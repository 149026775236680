<template>
	<div class="ig-dash-card">
		<v-row>
			<v-col>
                <h2>
                    {{ title }}
                </h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="font-weight-light" v-html="text">
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<slot name="buttons"></slot>
			</v-col>
		</v-row>
	</div>
</template>

<style lang="less">
@import "../../assets/css/variables.less";

.ig-dash-card {
    position: relative;
	padding: 19px;
	padding-left: 50px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
	color: white !important;
	background-color: @ig-purple !important;
	border-top: 2px solid @ig-green !important;
	border-bottom: 2px solid @ig-green !important;
	border-right: 2px solid @ig-green !important;
	border-left: transparent;
	&:after {
		content: "\ ";
		background-color: @primary;
		position: absolute;
		height: 120%;
        width: 100px;
        top: -10%;
        left: -75px;
        border-radius: 50%;
	}
}
</style>
<script>
// A single page element for the dark homepage style
// @group UserContent 

export default {
	name: "DashboardCard",
	props: {
		title: { type: String },
		text: { type: String },
		button: { type: String },
	},
};
</script>
