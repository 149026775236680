<template>
	<v-sheet color="transparent" class="white--text">
		<dashboard-title :text="$t('dashboard.feed')"></dashboard-title>
		<dashboard-card
			:title="$t('messages.delegate_welcome_title')"
			:text="$t('messages.delegate_welcome')"
		>
			<template v-slot:buttons>
				<v-btn color="green" rounded dark @click="$emit('start')">{{
					$t("buttons.start")
				}}</v-btn>
			</template>
		</dashboard-card>
		<dashboard-title
			:text="$t('dashboard.function_overview')"
		></dashboard-title>
		<v-row>
			<v-col>
				<p>
					Sie können:
				</p>
				<ul>
					<li>Kollegen einbinden,</li>
					<li>individuelle Ziele ergänzen und</li>
					<li>
						sich zu jeder Zeit aus- und wieder einloggen, ohne dass Ihre
						Ergebnisse verloren gehen.
					</li>
				</ul>
				<p class="mt-4">
					Bei Fragen kontaktieren Sie uns im Chat.
				</p>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script>
import DashboardTitle from "@c/dashboard/DashboardTitle";
import DashboardCard from "@c/dashboard/DashboardCard";
// All landing page content for a delegate
// @group UserContent
export default {
	name: "DelegateDashboard",
	components: {
		DashboardTitle,
		DashboardCard,
	},
};
</script>
