<template>
	<v-sheet color="transparent">
		<h1 class="font-weight-light white--text text-md">{{ $t("messages.hello") }}</h1>
		<v-spacer class="mb-10"></v-spacer>
		<delegate-dashboard v-if="isDelegate" @start="start">
		</delegate-dashboard>
		<lead-dashboard v-if="isLead" @start="start"> </lead-dashboard>
	</v-sheet>
</template>

<script>
import LeadDashboard from "@c/dashboard/LeadDashboard";
import DelegateDashboard from "@c/dashboard/DelegateDashboard";
import Vuex from "vuex";
export default {
	name: "Dashboard",
	components: {
		DelegateDashboard,
		LeadDashboard,
	},
	computed: {
		...Vuex.mapState({
			loggedIn: (state) => state.auth.logged_in,
		}),

		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		isDelegate() {
			return this.$store.getters["assessment/isDelegate"];
		},
		first() {
			return this.$store.getters.first;
		},
	},
	methods: {
		start() {
			const self = this;
			self.$store.commit("GO_TO", self.first);
			self.$router.replace("/assessment");
		},
	},
};
</script>
