<template>
	<div>
	</div>
</template>

<script>
import Vuex from "vuex";
export default {
	name: "Home",
	computed: {
		...Vuex.mapState({
			loggedIn: (state) => state.auth.logged_in,
		}),
	}
};
</script>
