<template>
	<v-row>
		<v-col>
			<span class="overline white--text">{{ text }}</span>
			<v-divider color="white"></v-divider>
		</v-col>
	</v-row>
</template>

<script>
// A single page title for the dark homepage style
// @group UserContent

export default {
	name: "DashboardTitle",
	props: {
		text: { type: String },
	},
};
</script>
