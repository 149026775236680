<template>
	<div>
		<v-simple-table class="overview-table">
			<thead>
				<tr>
					<th
						v-for="header in showHeaders"
						:key="header.value"
						:width="header.width"
					>
						{{ header.text }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, i) in items"
					:key="i"
					@click="goTo(item.department)"
				>
					<td>
						{{ item.section }}
					</td>
					<td>
						<response-action-buttons
							:id="item.department"
						></response-action-buttons>
					</td>
					<td v-if="!isMobile">
						<!-- <span v-if="item.user && users[item.user]">
							{{ users[item.user].displayName }} -->
						<!-- </span> -->
						{{getName(item.user)}}
					</td>
					<td v-if="!isMobile">
						{{ item.status }}
					</td>
					<td v-if="!isMobile">
						<span v-if="item.user && users[item.user]">
							{{getDate(item.user)}}
						</span>
					</td>
					<td v-if="!isMobile">
						<completion-rate
							linear
							:part="false"
							:department="item.department"
							:goal="false"
						></completion-rate>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
	</div>
</template>

<style lang="less">
@import "../../assets/css/variables.less";
.overview-table {
	padding: 10px;
	background: @ig-purple !important;
	border-radius: 12px;
	thead,
	tbody,
	tr,
	th,
	td {
		color: white !important;
		border-right: 1px solid @ig-green;
		&:last-child {
			border-right: none;
		}
	}
	tbody {
		td {
			border-top: 1px solid @ig-green;
		}
	}
}
</style>
<style lang="less" scoped>
@import "../../assets/css/variables.less";
.theme--light.v-data-table
	> .v-data-table__wrapper
	> table
	> tbody
	> tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
	background: @primary !important;
	cursor: pointer;
}
</style>

<script type="text/javascript">
import Vuex from "vuex";
import ResponseActionButtons from "@c/navigation/assessment-navigation/ResponseActionButtons";
import CompletionRate from "@c/navigation/assessment-navigation/CompletionRate";
// A list of all sections in the assessment
// @group - UserContent
export default {
	name: "Overviewtable",
	computed: {
		...Vuex.mapState({
			users: (state) => state.users.data,
			assessment: (state) => state.assessment.data,
			collaborations: (state) => state.collaborations.data,
			departments: (state) => state.departments.data,
		}),
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		flat() {
			return this.$store.getters.canEditView;
		},
		headers() {
			return [
				{
					value: "section",
					mobile: true,
					text: this.$t("dashboard.section"),
				},
				{ value: "actions", mobile: true, text: " ", width: "15%" },
				{ value: "user", text: this.$t("users.nav.single") },
				{ value: "collaborationStatus", text: this.$t("misc.status") },
				{ value: "lastLogin", text: this.$t("collaboration.last_login") },
				{ value: "progress", width: "24%", text: this.$t("misc.progress") },
			];
		},
		showHeaders() {
			if (this.isMobile) {
				return this.headers.filter((a) => a.mobile);
			}
			return this.headers;
		},
		items() {
			const self = this;
			var order = self.$store.getters["departments/canEditOrder"];
			var responseMap = self.$store.getters.responseMap;
			var items = order.map((o) => {
				var response = responseMap[o];
				var item = {};
				item.section = self.departments[o].name;
				item.department = o;
				item.status = self.$t(`collaboration.statuses.${response.status}`);
				item.collaboration = response.collaboration;
				var collaboration = self.collaborations[response.collaboration];
				if (collaboration) {
					item.collaborationStatus = this.$t(
						`collaboration.statuses.${collaboration.status}`
					);
					item.user = collaboration.user;
				}
				return item;
			});
			return items;
		},
	},
	components: {
		ResponseActionButtons,
		CompletionRate,
	},
	watch: {
		items: {
			immediate: true,
			handler() {
				const self = this;
				self.items.forEach((response) => {
					if (response.user) {
						self.$store.dispatch("users/fetchById", response.user);
					}
				});
			},
		},
	},
	methods: {
		goTo(id) {
			var first = this.flat.find((a) => {
				return a.department == id;
			});
			this.$store.commit("GO_TO", first);
			this.$router.replace("/assessment");
		},
		getName(id){
			if(!id){
				return ""
			}			
			let user = this.users[id];
			if( !user){
				return "";
			}
			return user.displayName;
		},
		getDate(id){
			if(!id){
				return ""
			}
			let user = this.users[id];
			if( !user){
				return "";
			}

			if( user.lastLogin ){
				return user.lastLogin.toDate().toLocaleDateString("de-DE");
			}
			if( user.created ){
				return user.created.toDate().toLocaleDateString("de-DE");
			}
			return;
// 
		}
	},
};
//
</script>
