<template>
	<span v-if="status !== 'complete'">
		<complete
			:disabled="!canComplete"
			v-if="isLead"
			:id="id"
			button="icon"
		></complete>
		<return :disabled="!canComplete" v-else :id="id" button="icon"></return>
		<v-dialog
			v-if="isLead"
			v-model="dialog"
			content-class="mw-medium-dialog"
		>
			<template v-slot:activator="{ on }">
				<v-btn icon :color="dark ? 'white' : 'igblack'" v-on="on">
					<v-icon>mdi-account-plus-outline</v-icon>
				</v-btn>
			</template>
			<v-card>
				<v-card-title>
					{{ $t("collaboration.delegate") }}
				</v-card-title>
				<v-card-text>
					<delegate :id="id"></delegate>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="dialog = false">OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<style lang="less">
.active {
}
</style>

<script type="text/javascript">
import Delegate from "@c/survey/tools/Delegate";
import Return from "@c/survey/tools/Return";
import Complete from "@c/survey/tools/Complete";
// A drop down menu accessed frm the navigation list of actions available to a single 
// section response
// @group Navigation
export default {
	name: "ResponseActionButtons",
	props: {
		id: { type: String }, //the id of the department
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		Complete,
		Return,
		Delegate,
	},
	computed: {
		response() {
			return this.$store.getters.responseMap[this.id] || false;
		},
		dark() {
			return this.$route.meta.dark;
		},
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		departmentCompletionRate() {
			var rates = this.$store.getters.rates;
			var department = this.id;
			var values = rates.filter((r) => r.department == department);
			return this.mwsurveyutilities.averageCompletionRate(values);
		},
		status() {
			return this.response ? this.response.status : "";
		},
		canComplete() {
			return this.departmentCompletionRate == 1;
		},
	},
};
//
</script>
"
