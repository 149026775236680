import { render, staticRenderFns } from "./OverviewTable.vue?vue&type=template&id=729adb9f&scoped=true&"
import script from "./OverviewTable.vue?vue&type=script&lang=js&"
export * from "./OverviewTable.vue?vue&type=script&lang=js&"
import style0 from "./OverviewTable.vue?vue&type=style&index=0&lang=less&"
import style1 from "./OverviewTable.vue?vue&type=style&index=1&id=729adb9f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729adb9f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
