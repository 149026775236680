<template>
	<v-sheet color="transparent" class="white--text">
		<template v-if="!haveConfig">
			<dashboard-title :text="$t('dashboard.feed')"></dashboard-title>
			<dashboard-card
				:text="$t('messages.lead_welcome')"
			>
				<template v-slot:buttons>
					<v-btn
						class="mr-2"
						color="green"
						rounded
						dark
						router-link
						to="/organisation-profile"
						>{{ $t("buttons.profile") }}</v-btn
					>
					<v-btn
						color="green"
						rounded
						dark
						to="/assessment-config/overview"
						>{{ $t("buttons.config") }}</v-btn
					>
				</template>
			</dashboard-card>
		</template>
		<v-row>
			<v-col>
				<overview-table></overview-table>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script>
import DashboardTitle from "@c/dashboard/DashboardTitle";
import DashboardCard from "@c/dashboard/DashboardCard";
import OverviewTable from "@c/dashboard/OverviewTable.vue";
// All landing page content for a Lead Respondent
// @group - UserContent 
export default {
	name: "LeadDashboard",
	components: {
		DashboardTitle,
		OverviewTable,
		DashboardCard,
	},
	computed: {
		haveConfig() {
			return this.$store.getters["organisation/haveConfig"];
		},
	},
};
</script>
